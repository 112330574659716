<template>
  <div>
    <!-- 重置密码1 -->
    <div class="login-content" v-if="type == 1">
      <div class="steps ub ub-cen">
        <div class="step-to ub ub-h-c">
          <span class="step-to">1</span>
        </div>
        <div class="ub ub-h-c" :class="{ isColor: oYeah }">
          <span>2</span>
        </div>
        <div class="ub ub-h-c">
          <span>3</span>
        </div>
      </div>
      <div class="info">
        <ul class="info-ul">
          <li class="init">验证身份</li>
          <li class>设置新密码</li>
          <li class>重置成功</li>
        </ul>
      </div>
      <div class="set">
        <p class="ex">请输入您需要重置登录密码的手机号</p>
        <el-form
          ref="codePhoneRef"
          :model="codePhoneForm"
          :rules="codePhoneRule"
          label-width="80px"
        >
          <el-form-item label="手机号" prop="userName">
            <el-input
              placeholder="请输入联系人手机号"
              v-model="codePhoneForm.userName"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="验证码" prop="passWord">
                <el-input
                  size="medium"
                  placeholder="请输入验证码"
                  v-model="codePhoneForm.passWord"
                  style="width: 157px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <div
                class="code"
                v-show="sendAuthCode"
                @click="
                  getVerificationCodeHandle('codePhoneRef', 'codePhoneForm')
                "
              >
                发送验证码
              </div>
              <div v-show="!sendAuthCode" class="codding">
                已发送验证码:{{ authTime }}
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-button
              type="danger"
              style="width: 100%"
              @click="changePasswordOneHandle('codePhoneRef', 'codePhoneForm')"
              :loading="loading1"
              >下一步</el-button
            >
          </el-row>
          <div class="over">
            <span @click="$router.push('/login')">返回登录</span>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 重置密码2 -->
    <div class="login-content" v-else-if="type == 2">
      <div class="steps ub ub-cen">
        <div class="step-to ub ub-h-c">
          <span class="step-to">1</span>
        </div>
        <div class="step-to ub ub-h-c" :class="{ isColor: oYeah }">
          <span>2</span>
        </div>
        <div class="ub ub-h-c">
          <span>3</span>
        </div>
      </div>
      <div class="info">
        <ul class="info-ul">
          <li class>验证身份</li>
          <li class="init">设置新密码</li>
          <li class>重置成功</li>
        </ul>
      </div>
      <div class="set">
        <p class="ex">请输入您需要重置登录密码的手机号</p>
        <el-form
          ref="updatePasswordRef"
          :model="updatePasswordForm"
          :rules="updatePasswordRule"
          label-width="110px"
        >
          <el-form-item label="设置新密码" prop="newPassword">
            <el-input
              type="password"
              placeholder="请输入密码"
              v-model="updatePasswordForm.newPassword"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="repeatPassword">
            <el-input
              type="password"
              placeholder="请再次输入密码"
              v-model="updatePasswordForm.repeatPassword"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-button
              type="danger"
              style="width: 100%"
              @click="
                changePasswordTwoHandle(
                  'updatePasswordRef',
                  'updatePasswordForm'
                )
              "
              :loading="loading2"
              >下一步</el-button
            >
          </el-row>
          <div class="over">
            <span @click="$router.push('/login')">返回登录</span>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 重置密码3 -->
    <div class="login-content" v-else>
      <div class="steps ub ub-cen">
        <div class="step-to ub ub-h-c">
          <span>1</span>
        </div>
        <div class="step-to ub ub-h-c">
          <span>2</span>
        </div>
        <div class="step-to ub ub-h-c">
          <span>3</span>
        </div>
      </div>
      <div class="info">
        <ul class="info-ul">
          <li class>验证身份</li>
          <li class>设置新密码</li>
          <li class="init">重置成功</li>
        </ul>
      </div>
      <div class="set2">
        <p class="set-sucess">重置密码成功</p>
        <div class="backhome" style="width: 100%">
          <el-row>
            <el-button
              type="danger"
              style="width: 100%"
              class="over-login"
              @click="$router.push('/login')"
              >回到首页</el-button
            >
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { changePasswordOne, changePasswordTwo } from "@/api/login";
import { validatePhone } from "@/utils/validate";

export default {
  data() {
    //自定义数据校验规则
    var validatePhonePage = (rule, value, callback) => {
      //console.debug(value);
      if (!validatePhone(value)) {
        callback(new Error("手机号码格式不正确"));
      }
      if (!value) {
        callback(new Error("手机号不能为空"));
      } else {
        callback();
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.updatePasswordForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      loading1: false,
      loading2: false,
      sendAuthCode: true, //发送验证码
      authTime: 0, //验证码有效倒计时

      validateToken: "",
      codePhoneForm: {
        userName: "",
        passWord: "",
        loginType: 2,
        verificationtype: "2",
      },
      codePhoneRule: {
        userName: [{ validator: validatePhonePage, trigger: "blur" }],
        passWord: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
      },
      updatePasswordForm: {
        newPassword: "",
        repeatPassword: "",
      },
      updatePasswordRule: {
        newPassword: [{ validator: validatePass, trigger: "blur" }],
        repeatPassword: [{ validator: validatePass2, trigger: "blur" }],
      },
      value: "",
      oYeah: false,
      type: 1,
    };
  },
  methods: {
    /**
     * @name 获取验证码
     */
    getVerificationCodeHandle() {
      this.getAuthCode();
      var username = this.codePhoneForm.userName;
      this.codePhoneForm.loginDomain = window.location.host;
      console.log(validatePhone(username),66677777)
      if (validatePhone(username)) {
        this.loading = true;
        this.$store
          .dispatch("getVerificationCode", this.codePhoneForm)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
        // alert('验证码已发送')
      }
    },

    /**
     * 重置密码第一步
     */
    changePasswordOneHandle(refName, subForm) {
      this.$refs[refName].validate((valid) => {
        if (valid) {
          changePasswordOne(this[subForm].userName, this[subForm].passWord)
            .then((response) => {
              if (response.data.code == 200) {
                const data = response.data;
                this.getAuthCode();
                //console.debug(data.data)
                this.validateToken = data.data;
                this.type = 2;
              } else {
                this.$router.push({ path: "/forgetpwd" });
              }
            })
            .catch(() => {});
        }
      });
    },

    /**
     * 重置密码第二步
     */
    changePasswordTwoHandle(refName, subForm) {
      this.$refs[refName].validate((valid) => {
        if (valid) {
          console.debug(subForm);
          changePasswordTwo(
            this[subForm].newPassword,
            this[subForm].repeatPassword,
            this.validateToken
          )
            .then((response) => {
              if (response.data.code == 200) {
                // const data = response.data;
                //console.debug(data.data)
                // commit('SET_TOKEN', data.data)
                // setToken(response.data.data)
                this.type = 3;
              } else {
                this.$router.push({ path: "/forgetpwd" });
              }
            })
            .catch(() => {});
        }
      });
    },
    /**
     * 验证码有效倒计时
     *
     */
    getAuthCode: function () {
      this.sendAuthCode = false;
      this.authTime = 60;
      var auth_timetimer = setInterval(() => {
        this.authTime--;
        if (this.authTime <= 0) {
          this.sendAuthCode = true;
          clearInterval(auth_timetimer);
        }
      }, 1000);
      console.log("ggg");
    },
  },
};
</script>
<style rel='stylesheet/scss' lang='scss'>
.login-content {
  /deep/ .el-form-item__label:before {
    content: "" !important;
  }
  /deep/ .el-form-item__label {
    color: rgba(110, 110, 110, 1) !important;
    height: 48px;
    line-height: 48px;
    font-size: 18px;
    text-align: left;
    font-weight: 100 !important;
  }
  /deep/ .el-input__inner {
    height: 48px;
  }
  /deep/ .el-button--medium {
    height: 48px;
  }
  /deep/ .el-button--danger {
    font-size: 24px;
  }
}
.info {
  width: 1179px;
  height: 40px;
  margin: 0 auto;
  li {
    width: 393px;
    font-size: 18px;
    text-align: center;
    float: left;
    margin-top: 16px;
  }
}
.codding {
  width: 168px;
  height: 48px;
  border: 1px solid gray;
  color: gray;
  background-color: rgba($color: gray, $alpha: 0.6);
  border-radius: 2px;
  line-height: 48px;
  float: right;
}
.set {
  width: 580px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.21);
  padding: 0 76px 87px;
  border-radius: 6px;
  margin: 32px auto;
  .ex {
    font-size: 18px;
    color: rgba(119, 119, 119, 1);
    padding: 30px 0 59px 0;
    margin-left: -20px;
  }
  .code {
    width: 168px;
    height: 48px;
    border: 1px solid rgba(230, 91, 91, 1);
    color: rgba(230, 91, 91, 1);
    border-radius: 2px;
    line-height: 48px;
    text-align: center;
    float: right;
  }
}
.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  div {
    width: 270px;
    height: 12px;
    // background:rgba(230,91,91,1);
    background: #ccc;
    text-align: center;
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      width: 32px;
      height: 32px;
      // background:rgba(230,91,91,1);
      background: #ccc;
      border-radius: 50%;
      text-align: center;
      line-height: 32px;
      margin-left: 119px;
      color: rgba(255, 255, 255, 1);
    }
  }
  .step-to {
    background: rgba(230, 91, 91, 1);
    span {
      background: rgba(230, 91, 91, 1);
    }
  }
}

.set2 {
  width: 580px;
  margin: 45px auto;
  padding: 70px 0 87px 0;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.21);
  border-radius: 6px;
}
.conture {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  font-size: 18px;
  border: 1px solid rgba(230, 91, 91, 1);
  margin: 0 auto;
  padding: 19px 12px;
}
.set-sucess {
  text-align: center;
  font-size: 24px;
  margin: 0 auto;
  padding: 62px 0 59px 0;
  color: rgba(230, 91, 91, 1);
  line-height: 22px;
}
.over-login {
  height: 48px;
  background: rgba(230, 91, 91, 1);
  border-radius: 4px;
}
.backhome {
  padding: 0 136px;
  box-sizing: border-box;
}
.init {
  color: rgba(230, 91, 91, 1);
}
.info {
  width: 810px;
  margin: 0 auto;
  li {
    width: 270px;
    font-size: 16px;
    text-align: center;
    float: left;
    // margin-top:16px;
  }
  .codding {
    width: 168px;
    height: 48px;
    border: 1px solid gray;
    color: gray;
    background-color: rgba($color: gray, $alpha: 0.6);
    border-radius: 2px;
    line-height: 48px;
    float: right;
  }
}
.over {
  cursor: pointer;
  color: rgba(102, 102, 102, 1);
  text-align: center;
  font-size: 16px;
  margin-left: 16px;
  margin-top: 25px;
  span {
    color: #e65b5b;
  }
}
</style>
